<template>
	<div>
		<div class="app-heading pd:md">
			<div v-t="'faq'" class="app-title"></div>
		</div>

		<section
			v-show="isLoading"
			class="preloader">
		</section>
		<section
			v-show="!isLoading"
			class="pd:md">
			<div class="info-list" v-html="html_content"></div>
		</section>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'faq',
	inject: ['$util'],
	data () {
		return {
			html_content: ''
		}
	},
	computed: {
		...mapState('i18n', {
			localeDefault: 'default'
		})
	},
	methods: {
		async reloadHtml () {
			this.isLoading = true

			const payload = []

			const modelFaq = 'get_faq_content'
			const payloadFaq = {
				policy: [
					{ [modelFaq]: [{ args: [{ locale: this.localeDefault }] }] }
				]
			}

			payload.push(payloadFaq)

			const responses = await this.$store.dispatch('api/fetch', {
				method: 'POST',
				payload: payload
			})

			if (responses.policy[modelFaq][0][0].code === 2001) {
				this.html_content = responses.policy[modelFaq][0][0].data.html_content

				// Convert the HTML string into a document object
				const parser = new DOMParser()
				const doc = parser.parseFromString(this.html_content, 'text/html')
				this.html_content = doc.body.textContent
			} else {
				this.html_content = 'No FAQ found.'
			}

			this.isLoading = false
		}
	},
	activated () {
		this.$util.scrollToTop()
	},
	created () {
		this.reloadHtml()
	},
	watch: {
		localeDefault: {
			handler () {
				this.reloadHtml()
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
section {
	background-color: $color-background;

	/deep/ a {
		display: inline-block;
		color: $color-accent-turquoise !important;
		text-decoration: underline !important;
	}
}

/deep/ .info-list {
	p { margin: 0 }

	& > ol {
		li {
			margin-left: $pd;
			padding-left: $pd / 2;

			b {
				display: inline-block;
				margin-bottom: $pd / 2;
			}
		}

		& > li {
			list-style-type: decimal;

			&:not(:last-child) {
				margin-bottom: $pd;
			}
		}

		ol {
			margin-left: $pd;
			padding-left: $pd / 2;

			& > li {
				list-style-type: decimal;

				&:first-of-type { margin-top: $pd / 2 }
				&:last-child { margin-bottom: $pd }
			}
		}
	}
}

// .info-list {
// 	li {
// 		margin-left: $pd;
// 		padding-left: $pd / 2;
// 	}

// 	& > li {
// 		list-style-type: decimal;

// 		&:not(:last-child) {
// 			margin-bottom: $pd;
// 		}

// 		&.entry {
// 			font-weight: 700;

// 			.question {
// 				margin-bottom: $pd / 2;
// 			}

// 			.answer {
// 				font-weight: initial;

// 				& > ol {

// 					& > li {
// 						list-style-type: decimal;

// 						&:first-of-type { margin-top: $pd / 2 }
// 					}
// 				}
// 			}
// 		}
// 	}
// }
</style>
